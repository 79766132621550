//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Header',
    data() {
        return {
            uniacid:window.localStorage.getItem('uniacid'),
            //uniacid:17,
			locale: 'EN', //使用i18n实例默认定义的语言
			locales: [
			    {
			        value: "EN",
			        label: "English",
			    },
			    {
			        value: "zh-CN",
			        label: "中文简体",
			    },
			    {
			        value: "zh-HK",
			        label: "中文繁體",
			    },
			],
			langs: ['zh-HK', 'en'],
            currency: 'HKD',
            currencies: [
			    {
			        value: "HKD",
			        label: "HKD",
			    },
			    {
			        value: "USD",
			        label: "USD",
			    },
			],
            config: [
                // {label: '最新消息', path: '/newslist'},
                {label: this.$t('index'),locate:'index', path: '../home'},
                {label: this.$t('Login/Logon'), locate:'Login/Logon',path: '/login'},
                {label: this.$t('Myorders'),locate:'Myorders', path: '/user', type: '0'},
                {label: this.$t('Mycenter'),locate:'Mycenter', path: '/user'},
                {label: this.$t('Cart'),locate:'Cart', path: '/cart', type:'cart', class: 'active', icon: '&#xe70b;'},
            ],
            userInfo: null,
        }
    },
	created() {
	 
	    const locale = window.localStorage.getItem('locale') || this.$i18n.locale
	    this.locale = locale
	    this.$i18n.locale = locale

        const currency = window.localStorage.getItem('currency')
        if(!currency || currency === null || currency.length < 2) {
            this.currency = 'HKD'
            window.localStorage.setItem('currency', 'HKD')
        } else {
            this.currency = currency
        }

        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        //是否是二级域名 
        var centerdomain= www_dz_url.split(".");
        if(centerdomain[0] === 'trillionglory') {
        //if(centerdomain[0] === 'localhost:8081') {
            this.showCurrency = true;
        }
        this.uniacid=window.localStorage.getItem('uniacid')
        console.log('headerrrrrrrrrrrrrrrrrrrr: ' + this.uniacid)
        //this.uniacid=window.localStorage.getItem('uniacid')

	    
	},
    methods: {
        goto(path, type='0') {
            if(path == '/login' && this.userInfo){
                this.setCookie('PHPSESSID', '', -1)
                this.$store.commit('saveUserInfo',null)
                window.localStorage.setItem('fb_user_id', '');
                window.localStorage.setItem('fb_name', '');
                this.$router.push({name:'Home'})
            }else{
                this.$router.push({path, query: {type,}})
            }   
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;

        },   
		changeLanguage(value) {
            this.locale = value
            window.localStorage.setItem('locale', value)
			   this.$i18n.locale = value
           window.location.reload()
        },
        changeCurrency(value) {
            this.currency = value
            window.localStorage.setItem('currency', value)
           window.location.reload()
        },
    },
    watch: {
        '$store.state.userInfo':function(info){
            this.userInfo = info
			console.log('info:',info);
            if(this.userInfo){
				console.log('login ok...');
                this.config[1].label =  this.$t('Logout')
                this.config[1].locate =  this.$t('Logout')
            }else{
                this.config[1].label = this.$t('Login/Logon')
            }   
            
        }
    }

}
